// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-de-bestellformular-js": () => import("./../../../src/pages/de/bestellformular.js" /* webpackChunkName: "component---src-pages-de-bestellformular-js" */),
  "component---src-pages-de-bestellformular-vielen-dank-js": () => import("./../../../src/pages/de/bestellformular-vielen-dank.js" /* webpackChunkName: "component---src-pages-de-bestellformular-vielen-dank-js" */),
  "component---src-pages-de-datenschutz-js": () => import("./../../../src/pages/de/datenschutz.js" /* webpackChunkName: "component---src-pages-de-datenschutz-js" */),
  "component---src-pages-de-impressum-js": () => import("./../../../src/pages/de/impressum.js" /* webpackChunkName: "component---src-pages-de-impressum-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-inspiration-js": () => import("./../../../src/pages/de/inspiration.js" /* webpackChunkName: "component---src-pages-de-inspiration-js" */),
  "component---src-pages-de-kontakt-js": () => import("./../../../src/pages/de/kontakt.js" /* webpackChunkName: "component---src-pages-de-kontakt-js" */),
  "component---src-pages-de-kontakt-vielen-dank-js": () => import("./../../../src/pages/de/kontakt-vielen-dank.js" /* webpackChunkName: "component---src-pages-de-kontakt-vielen-dank-js" */),
  "component---src-pages-de-partner-werden-js": () => import("./../../../src/pages/de/partner-werden.js" /* webpackChunkName: "component---src-pages-de-partner-werden-js" */),
  "component---src-pages-de-partnerschaft-js": () => import("./../../../src/pages/de/partnerschaft.js" /* webpackChunkName: "component---src-pages-de-partnerschaft-js" */),
  "component---src-pages-de-produkt-bio-schwarzkummelol-1-js": () => import("./../../../src/pages/de/produkt/bio-schwarzkummelol-1.js" /* webpackChunkName: "component---src-pages-de-produkt-bio-schwarzkummelol-1-js" */),
  "component---src-pages-de-produkt-bio-schwarzkummelol-js": () => import("./../../../src/pages/de/produkt/bio-schwarzkummelol.js" /* webpackChunkName: "component---src-pages-de-produkt-bio-schwarzkummelol-js" */),
  "component---src-pages-de-produkt-bonbons-mit-schwarzkummel-kurkuma-minze-und-zitrone-js": () => import("./../../../src/pages/de/produkt/bonbons-mit-schwarzkummel-kurkuma-minze-und-zitrone.js" /* webpackChunkName: "component---src-pages-de-produkt-bonbons-mit-schwarzkummel-kurkuma-minze-und-zitrone-js" */),
  "component---src-pages-de-produkt-hautbalsam-mit-schwarzkummel-argan-jojoba-avocado-sheabutter-js": () => import("./../../../src/pages/de/produkt/hautbalsam-mit-schwarzkummel-argan-jojoba-avocado-sheabutter.js" /* webpackChunkName: "component---src-pages-de-produkt-hautbalsam-mit-schwarzkummel-argan-jojoba-avocado-sheabutter-js" */),
  "component---src-pages-de-produkt-schampoo-mit-argan-und-schwarzkummel-js": () => import("./../../../src/pages/de/produkt/schampoo-mit-argan-und-schwarzkummel.js" /* webpackChunkName: "component---src-pages-de-produkt-schampoo-mit-argan-und-schwarzkummel-js" */),
  "component---src-pages-de-produkt-schwarzkummelol-in-kapseln-1-js": () => import("./../../../src/pages/de/produkt/schwarzkummelol-in-kapseln-1.js" /* webpackChunkName: "component---src-pages-de-produkt-schwarzkummelol-in-kapseln-1-js" */),
  "component---src-pages-de-produkt-schwarzkummelol-in-kapseln-js": () => import("./../../../src/pages/de/produkt/schwarzkummelol-in-kapseln.js" /* webpackChunkName: "component---src-pages-de-produkt-schwarzkummelol-in-kapseln-js" */),
  "component---src-pages-de-produkt-sheabutterseife-mit-schwarzkummelol-js": () => import("./../../../src/pages/de/produkt/sheabutterseife-mit-schwarzkummelol.js" /* webpackChunkName: "component---src-pages-de-produkt-sheabutterseife-mit-schwarzkummelol-js" */),
  "component---src-pages-de-produkte-js": () => import("./../../../src/pages/de/produkte.js" /* webpackChunkName: "component---src-pages-de-produkte-js" */),
  "component---src-pages-de-uber-uns-js": () => import("./../../../src/pages/de/uber-uns.js" /* webpackChunkName: "component---src-pages-de-uber-uns-js" */),
  "component---src-pages-es-404-js": () => import("./../../../src/pages/es/404.js" /* webpackChunkName: "component---src-pages-es-404-js" */),
  "component---src-pages-es-aviso-legal-js": () => import("./../../../src/pages/es/aviso-legal.js" /* webpackChunkName: "component---src-pages-es-aviso-legal-js" */),
  "component---src-pages-es-colaboracion-js": () => import("./../../../src/pages/es/colaboración.js" /* webpackChunkName: "component---src-pages-es-colaboracion-js" */),
  "component---src-pages-es-contacto-gracias-js": () => import("./../../../src/pages/es/contacto-gracias.js" /* webpackChunkName: "component---src-pages-es-contacto-gracias-js" */),
  "component---src-pages-es-contacto-js": () => import("./../../../src/pages/es/contacto.js" /* webpackChunkName: "component---src-pages-es-contacto-js" */),
  "component---src-pages-es-formulario-para-pedidos-gracias-js": () => import("./../../../src/pages/es/formulario-para-pedidos-gracias.js" /* webpackChunkName: "component---src-pages-es-formulario-para-pedidos-gracias-js" */),
  "component---src-pages-es-formulario-para-pedidos-js": () => import("./../../../src/pages/es/formulario-para-pedidos.js" /* webpackChunkName: "component---src-pages-es-formulario-para-pedidos-js" */),
  "component---src-pages-es-hazte-socio-gracias-js": () => import("./../../../src/pages/es/hazte-socio-gracias.js" /* webpackChunkName: "component---src-pages-es-hazte-socio-gracias-js" */),
  "component---src-pages-es-hazte-socio-js": () => import("./../../../src/pages/es/hazte-socio.js" /* webpackChunkName: "component---src-pages-es-hazte-socio-js" */),
  "component---src-pages-es-index-js": () => import("./../../../src/pages/es/index.js" /* webpackChunkName: "component---src-pages-es-index-js" */),
  "component---src-pages-es-inspiracion-js": () => import("./../../../src/pages/es/inspiracion.js" /* webpackChunkName: "component---src-pages-es-inspiracion-js" */),
  "component---src-pages-es-intimidad-js": () => import("./../../../src/pages/es/intimidad.js" /* webpackChunkName: "component---src-pages-es-intimidad-js" */),
  "component---src-pages-es-producto-aceite-de-comino-negro-ecologico-1-js": () => import("./../../../src/pages/es/producto/aceite-de-comino-negro-ecologico-1.js" /* webpackChunkName: "component---src-pages-es-producto-aceite-de-comino-negro-ecologico-1-js" */),
  "component---src-pages-es-producto-aceite-de-comino-negro-ecologico-js": () => import("./../../../src/pages/es/producto/aceite-de-comino-negro-ecologico.js" /* webpackChunkName: "component---src-pages-es-producto-aceite-de-comino-negro-ecologico-js" */),
  "component---src-pages-es-producto-aceite-de-comino-negro-en-capsulas-1-js": () => import("./../../../src/pages/es/producto/aceite-de-comino-negro-en-capsulas-1.js" /* webpackChunkName: "component---src-pages-es-producto-aceite-de-comino-negro-en-capsulas-1-js" */),
  "component---src-pages-es-producto-aceite-de-comino-negro-en-capsulas-js": () => import("./../../../src/pages/es/producto/aceite-de-comino-negro-en-capsulas.js" /* webpackChunkName: "component---src-pages-es-producto-aceite-de-comino-negro-en-capsulas-js" */),
  "component---src-pages-es-producto-balsamo-para-la-piel-con-comino-negro-argan-jojoba-aguacate-y-manteca-de-karite-js": () => import("./../../../src/pages/es/producto/balsamo-para-la-piel-con-comino-negro-argan-jojoba-aguacate-y-manteca-de-karite.js" /* webpackChunkName: "component---src-pages-es-producto-balsamo-para-la-piel-con-comino-negro-argan-jojoba-aguacate-y-manteca-de-karite-js" */),
  "component---src-pages-es-producto-caramelos-de-comino-negro-curcuma-menta-y-limon-js": () => import("./../../../src/pages/es/producto/caramelos-de-comino-negro-curcuma-menta-y-limon.js" /* webpackChunkName: "component---src-pages-es-producto-caramelos-de-comino-negro-curcuma-menta-y-limon-js" */),
  "component---src-pages-es-producto-champu-de-argan-y-comino-negro-js": () => import("./../../../src/pages/es/producto/champu-de-argan-y-comino-negro.js" /* webpackChunkName: "component---src-pages-es-producto-champu-de-argan-y-comino-negro-js" */),
  "component---src-pages-es-producto-jabon-de-manteca-de-karite-con-aceite-de-comino-js": () => import("./../../../src/pages/es/producto/jabon-de-manteca-de-karite-con-aceite-de-comino.js" /* webpackChunkName: "component---src-pages-es-producto-jabon-de-manteca-de-karite-con-aceite-de-comino-js" */),
  "component---src-pages-es-productos-js": () => import("./../../../src/pages/es/productos.js" /* webpackChunkName: "component---src-pages-es-productos-js" */),
  "component---src-pages-es-sobre-nosotros-js": () => import("./../../../src/pages/es/sobre-nosotros.js" /* webpackChunkName: "component---src-pages-es-sobre-nosotros-js" */),
  "component---src-pages-impresum-js": () => import("./../../../src/pages/impresum.js" /* webpackChunkName: "component---src-pages-impresum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspiracija-js": () => import("./../../../src/pages/inspiracija.js" /* webpackChunkName: "component---src-pages-inspiracija-js" */),
  "component---src-pages-kontakt-hvala-js": () => import("./../../../src/pages/kontakt-hvala.js" /* webpackChunkName: "component---src-pages-kontakt-hvala-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nama-js": () => import("./../../../src/pages/o-nama.js" /* webpackChunkName: "component---src-pages-o-nama-js" */),
  "component---src-pages-partnerstvo-js": () => import("./../../../src/pages/partnerstvo.js" /* webpackChunkName: "component---src-pages-partnerstvo-js" */),
  "component---src-pages-postani-partner-hvala-js": () => import("./../../../src/pages/postani-partner-hvala.js" /* webpackChunkName: "component---src-pages-postani-partner-hvala-js" */),
  "component---src-pages-postani-partner-js": () => import("./../../../src/pages/postani-partner.js" /* webpackChunkName: "component---src-pages-postani-partner-js" */),
  "component---src-pages-proizvod-bundevino-ulje-js": () => import("./../../../src/pages/proizvod/bundevino-ulje.js" /* webpackChunkName: "component---src-pages-proizvod-bundevino-ulje-js" */),
  "component---src-pages-proizvod-corokotovo-ulje-js": () => import("./../../../src/pages/proizvod/corokotovo-ulje.js" /* webpackChunkName: "component---src-pages-proizvod-corokotovo-ulje-js" */),
  "component---src-pages-proizvod-konopljino-ulje-js": () => import("./../../../src/pages/proizvod/konopljino-ulje.js" /* webpackChunkName: "component---src-pages-proizvod-konopljino-ulje-js" */),
  "component---src-pages-proizvod-laneno-ulje-js": () => import("./../../../src/pages/proizvod/laneno-ulje.js" /* webpackChunkName: "component---src-pages-proizvod-laneno-ulje-js" */),
  "component---src-pages-proizvodi-js": () => import("./../../../src/pages/proizvodi.js" /* webpackChunkName: "component---src-pages-proizvodi-js" */),
  "component---src-pages-zastita-podataka-js": () => import("./../../../src/pages/zastita-podataka.js" /* webpackChunkName: "component---src-pages-zastita-podataka-js" */)
}

